<template>
  <div class="page-wrap text-center">
    <div class="session-form-hold">
      <base-card>
        <v-card-text class="text-center">
          <img
            class="mb-4"
            height="50"
            src="@/assets/images/logo/logo-dark.svg"
            alt=""
          />
          <h5>Esqueceu a senha?</h5>
          <h6 class="text--disabled font-weight-medium mb-10">
            Informe seu WhatsApp para enviarmos um link de recuperação
          </h6>
          <phone-input
            ref="phoneInput"
            :phone="whatsapp"
            @update:phone="(e) => (whatsapp = e)"
            :ddi="ddi"
            @update:ddi="(e) => (ddi = e)"
            @valid="(v) => (valid = v)"
            dense
            outlined
            :disabled="loading"
          />
          <v-fade-transition hide-on-leave>
            <v-alert
              v-show="success"
              border="left"
              class="text-left"
              text
              type="success"
            >
              Link de recuperação enviado para o WhatsApp informado!
            </v-alert>
          </v-fade-transition>
          <v-fade-transition hide-on-leave>
            <v-alert
              v-show="error"
              border="left"
              class="text-left"
              text
              icon="mdi-alert-circle-outline"
              type="error"
            >
              {{ error }}
            </v-alert>
          </v-fade-transition>
          <v-btn
            :disabled="!valid || success"
            color="primary"
            block
            :loading="loading"
            @click="processRecaptcha"
            class="mb-3"
          >
            Enviar
          </v-btn>
          <v-btn
            :disabled="loading"
            small
            text
            block
            @click="$root.$emit('lost-phone-access')"
          >
            Não tenho acesso a esse número
          </v-btn>
        </v-card-text>
      </base-card>
    </div>
    <re-captcha
      ref="recaptcha"
      @success="process"
      @error="recaptchaError"
      @close="recaptchaError"
    />
    <lost-phone-access />
  </div>
</template>
<script>
import ReCaptcha from "@/components/global/ReCaptcha.vue";
import AUTH from "@/services/auth";
import LostPhoneAccess from "@/components/global/LostPhoneAccess.vue";
import PhoneInput from "../../../components/global/PhoneInput.vue";

export default {
  components: { ReCaptcha, LostPhoneAccess, PhoneInput },
  name: "ForgotPassword",
  metaInfo: {
    title: "Redefinir Senha",
  },
  data() {
    return {
      whatsapp: "",
      ddi: 55,
      loading: false,
      error: false,
      valid: false,
      success: false,
    };
  },
  methods: {
    processRecaptcha() {
      this.loading = true;
      this.error = false;
      this.$refs.recaptcha.execute();
    },
    async process(recaptcha) {
      try {
        this.loading = true;

        var response = await AUTH.resetPassword.requestToken({
          phone: this.whatsapp,
          ddi: this.ddi,
          recaptcha,
        });
        this.success = true;
      } catch (e) {
        this.error = e.message;
      } finally {
        this.loading = false;
      }
    },
    recaptchaError(error) {
      this.loading = false;
      this.error = error;
    },
  },
  watch: {
    whatsapp() {
      this.error = false;
      this.success = false;
    },
  },
  mounted() {
    if (this.$route.query.phone && this.$route.query.ddi) {
      // Aply phone mask query param
      this.$nextTick(() => {
        this.whatsapp = this.$route.query.phone;
        this.ddi = parseInt(this.$route.query.ddi);
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.page-wrap {
  background-color: #B01C3F !important;
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>
